<script setup lang="ts">
const modalStore = useModalStore();
const patientStore = usePatientStore();

const { setValidation, required, email } = useValidation();

const patientData = reactive({
  firstname: '',
  lastname: '',
  email: '',
  gender: '',
  date_of_birth: '',
});

watch(
  () => patientStore.activePatient,
  (patient) => {
    if (!patient) {
      return;
    }

    patientData.firstname = patient.firstname;
    patientData.lastname = patient.lastname;
    patientData.email = patient.email;
    patientData.gender = patient.gender;
    patientData.date_of_birth = patient.date_of_birth;
  },
  { immediate: true },
);

const validation = setValidation(patientData, {
  firstname: { required },
  lastname: { required },
  email: { required, email },
  gender: { required },
  date_of_birth: { required },
});

const isUpdatePatient = computed(() => !!patientStore.activePatient);

async function handleUpsertPatient() {
  if (isUpdatePatient.value) {
    await patientStore.updatePatient(
      patientStore.activePatient.patientUserId,
      patientData,
    );
  } else {
    await patientStore.createPatient(patientData);
  }
  await modalStore.closeModal();
}
</script>

<template>
  <BaseModal
    :title="
      $t(`modal.patientUpsert.${isUpdatePatient ? 'update' : 'create'}.title`)
    "
    :width="900"
  >
    <form>
      <BaseInput
        v-model="validation.firstname.$model"
        label="Prénom"
        type="text"
        :validation="validation.firstname"
        required
        :disabled="isUpdatePatient"
      />

      <BaseInput
        v-model="validation.lastname.$model"
        label="Nom"
        type="text"
        :validation="validation.lastname"
        required
        :disabled="isUpdatePatient"
      />

      <BaseInput
        v-model="validation.email.$model"
        label="E-mail"
        type="text"
        :validation="validation.email"
        required
        :disabled="isUpdatePatient"
      />

      <BaseSelect
        v-model="validation.gender.$model"
        label="Sexe"
        :validation="validation.gender"
        :options="[
          {
            label: 'Homme',
            value: 'male',
          },
          {
            label: 'Femme',
            value: 'female',
          },
        ]"
      />

      <BaseDatePicker
        v-model="validation.date_of_birth.$model"
        label="Date de naissance"
        placeholder="Renseigner la date"
        :validation="validation.date_of_birth"
      />
    </form>

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton
        text="Confirmer"
        @click="handleUpsertPatient"
        :disabled="validation.$errors.length"
      />
    </template>
  </BaseModal>
</template>

<style scoped></style>
